import React, { useState } from 'react'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { IPost } from '~/types'
import { contentWidth, height16x9, height4x3, linesRem } from '~/utils/styling'
import { GatsbyImageSharpFluidFragment } from '~/types'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { useAppContext } from '~/contexts/appContext'

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid #bfbfbf;
  margin: auto;
  font-size: 14px;
`
const Tr = styled.tr`
  border-bottom: 1px solid #bfbfbf;
  &:last-child {
    border-bottom: none;
  }
  &:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .desc {
    font-size: 12px;
  }
`

const Td = styled.td`
  border-right: 1px solid #bfbfbf;
  padding: 5px 10px;
  &.title {
    font-weight: bold;
    text-align: right;
    width: 24%;
  }
  &.value {
    font-style: italic;
    color: rgba(0, 0, 0, 0.65);
  }
  &:last-child {
    border-right: none;
  }
`

const Hr = styled.hr`
  opacity: 0.25;
`

interface BookData {
  year: number
  title: string
  paper?: string
  pageCount?: number
  grif?: string
  isbn?: string
  description?: string
  publisher?: string
  binding?: string
}

interface IBookViewProps {
  book: IPost
}

const BookData: React.FC<{ bookData: BookData }> = ({ bookData }) => {
  return (
    <>
      <h3>Характеристики:</h3>
      <Table>
        <tbody>
        <Tr>
          <Td className="title">Название: </Td>
          <Td className="value">
            <strong>{bookData.title}</strong>
          </Td>
        </Tr>
        <Tr>
          <Td className="title">Год издания: </Td>
          <Td className="value">{bookData.year}</Td>
        </Tr>
        {bookData.isbn && (
          <Tr>
            <Td className="title">ISBN: </Td>
            <Td className="value">{bookData.isbn}</Td>
          </Tr>
        )}
        {bookData.grif && (
          <Tr>
            <Td className="title">Гриф ИС РПЦ: </Td>
            <Td className="value">{bookData.grif}</Td>
          </Tr>
        )}
        {bookData.publisher && (
          <Tr>
            <Td className="title">Издатетельство: </Td>
            <Td className="value">{bookData.publisher}</Td>
          </Tr>
        )}
        {bookData.binding && (
        <Tr>
          <Td className="title">Переплет: </Td>
          <Td className="value">{bookData.binding}</Td>
        </Tr>
        )}
        {bookData.paper && (
        <Tr>
          <Td className="title">Бумага: </Td>
          <Td className="value">{bookData.paper}</Td>
        </Tr>
        )}
        {bookData.pageCount && (
        <Tr>
          <Td className="title">К-во страниц: </Td>
          <Td className="value">{bookData.pageCount}</Td>
        </Tr>
        )}
        {bookData.description && (
          <Tr>
            <Td className="title">Описание: </Td>
            <Td className="desc">{bookData.description}</Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </>
  )
}

const BookView: React.FC<IBookViewProps> = ({ book }) => {
  const { state } = useAppContext()

  let image = null

  if (book.cover) {
    image = (
      <Img
        fluid={book.cover as GatsbyImageSharpFluidFragment}
        style={{ maxHeight: `${height4x3}px` }}
        imgStyle={{
          objectFit: 'contain',
          // border: `3px solid white`,
          // maxHeight: `${height4x3}px`,
        }}
      />
    )
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  function toggleModal() {
    if (state.pageWidth <= 420) return
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <article className="fixed-width mb-5">
      <header className="mt-2 mb-2" style={{ textAlign: 'center' }}>
        <h2>{book.title}</h2>
      </header>
      <main>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={toggleModal} allowFullscreen={false}>
              <Carousel
                views={[{ src: book.cover.src }]}
                components={{ Footer: null }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <div onClick={toggleModal}>{image}</div>
        <MDXRenderer>{book.body}</MDXRenderer>
        {book.bookInfo && <BookData bookData={{...book.bookInfo, year: book.year} as any} />}
      </main>
    </article>
  )
}

export default BookView
