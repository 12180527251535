import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import SEO from '~/components/SEO'
import { BookPageByUrlPathQuery, IGasbyPageProps } from '../types'
import BookView from '~/components/BookView'
import { PostDataMdxFragmentFragment } from '~/types'
import { formatPostFromData } from '~/utils/post'


interface IBookPageViewTemplateProps extends IGasbyPageProps {
  data: BookPageByUrlPathQuery
}

const BookPageViewTemplate: React.FC<IBookPageViewTemplateProps> = ({ data, uri }) => {
  const book = formatPostFromData(data.mdx as PostDataMdxFragmentFragment)
  
  const meta = [
    {
      property: `og:url`,
      content: `${data.site.siteMetadata.siteUrl}${book.urlPath}`,
    },
  ]

  if (book.cover) {
    meta.push({
      property: `og:image`,
      content: book.cover.src,
    })
  }

  if (book.coverSize && book.coverSize.width) {
    meta.push({
      property: `og:image:width`,
      content: book.coverSize.width.toString(),
    })
  }

  if (book.coverSize && book.coverSize.height) {
    meta.push({
      property: `og:image:height`,
      content: book.coverSize.height.toString(),
    })
  }

  return (
    <Layout uri={uri}>
      <BookView book={book}></BookView>
      <SEO title={book.title} description={book.desc} meta={meta}></SEO>
    </Layout>
  )
}
export default BookPageViewTemplate

export const query = graphql`
  query bookPageByURLPath($urlPath: String!, $contentWidth: Int!) {
    mdx(fields: { urlPath: { eq: $urlPath } }) {
      ...PostDataMdxFragment
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`