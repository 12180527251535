import { graphql } from 'gatsby'
import {
  IPost,
  TSize,
  PostDataFragmentFragment,
  PostDataMdxFragmentFragment,
  PostsForPageQuery,
  BooksForPageQuery,
  PostsForIndexPageQuery,
} from '~/types'

export const PostDataFragment = graphql`
  fragment PostDataFragment on MarkdownRemark {
    id
    html
    excerpt(format: PLAIN)
    timeToRead
    wordCount {
      words
      sentences
      paragraphs
    }
    frontmatter {
      title
      urlKey
      type
      desc
      tags
      audioURL
      audio {
        publicURL
        size
        prettySize
      }
      audioList {
        title
        file {
          publicURL
          size
          prettySize
        }
      }
      youtube
      rutube
      instagram
      vk
      timeOffset
      date(formatString: "DD MMMM, YYYY", locale: "ru")
      cover {
        childImageSharp {
          fluid(maxWidth: $contentWidth, quality: 70) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            width
          }
        }
      }
      images {
        childImageSharp {
          fluid(maxWidth: $contentWidth) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            width
          }
        }
      }
      imageOptions {
        objectFit
        maxHeight
      }
    }
    fields {
      urlPath
      dateValue
      renderer
    }
  }
`

export const PostDataMdxFragment = graphql`
  fragment PostDataMdxFragment on Mdx {
    id
    body
    frontmatter {
      title
      urlKey
      type
      desc
      tags
      audioURL
      audio {
        publicURL
        size
        prettySize
      }
      audioList {
        title
        file {
          publicURL
          size
          prettySize
        }
      }
      youtube
      rutube
      instagram
      vk
      timeOffset
      date(formatString: "DD MMMM, YYYY", locale: "ru")
      cover {
        childImageSharp {
          fluid(maxWidth: $contentWidth, quality: 70) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            width
          }
        }
      }
      images {
        childImageSharp {
          fluid(maxWidth: $contentWidth) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            width
          }
        }
      }
      imageOptions {
        objectFit
        maxHeight
      }
      year
      bookInfo {
        title
        binding
        description
        grif
        isbn
        pageCount
        paper
        publisher
      }
    }
    fields {
      urlPath
      dateValue
      renderer
    }
  }
`

export function formatPostListFromData(data: PostsForPageQuery | PostsForIndexPageQuery | BooksForPageQuery): IPost[] {
  const posts: IPost[] = []
  
  if ((data as PostsForPageQuery | PostsForIndexPageQuery).allMarkdownRemark) {
    (data as PostsForPageQuery | PostsForIndexPageQuery).allMarkdownRemark.edges.forEach(({ node }, index) => {
      posts.push(formatPostFromData(node, index))
    })
  }
  
  if ((data as BooksForPageQuery).allMdx) {
    (data as BooksForPageQuery).allMdx.edges.forEach(({ node }, index) => {
      posts.push(formatPostFromData(node, index))
    })
  }

  return posts
}

export function formatPostFromData(data: PostDataFragmentFragment | PostDataMdxFragmentFragment, index: number = null): IPost {

  const fm = data.frontmatter
  const cover = fm.cover ? fm.cover.childImageSharp.fluid : null

  const audioUrl = fm.audio
    ? fm.audio.publicURL
    : fm.audioURL
      ? fm.audioURL
      : null

  const wordCount = (data as PostDataFragmentFragment).wordCount ? { ...(data as PostDataFragmentFragment).wordCount } : undefined

  const coverSize =
    cover && fm.cover.childImageSharp.original
      ? {
        width: fm.cover.childImageSharp.original.width,
        height: fm.cover.childImageSharp.original.height
      }
      : null

  const images = fm.images 
    ? fm.images.map(img => img.childImageSharp.fluid) 
    : null

  const minImagesSize = fm.images
    ? fm.images.reduce((acc, img) => {
      if (img.childImageSharp.original.width < acc.width) {
        acc = { ...img.childImageSharp.original }
      }
      return acc
    }, fm.images[0].childImageSharp.original) as TSize
    : null

  const audio = fm.audio
    ? {
      title: '',
      url: fm.audio.publicURL,
      size: fm.audio.size,
      prettySize: fm.audio.prettySize
    }
    : null

    const audioList = fm.audioList
      ? fm.audioList.filter(it => !!it.file).map(it => ({
        title: it.title,
        url: it.file.publicURL,
        size: it.file.size,
        prettySize: it.file.prettySize
      }))
      : null

  return {
    indexOnPage: index,
    excerpt: (data as PostDataFragmentFragment).excerpt ? (data as PostDataFragmentFragment).excerpt : undefined,
    title: fm.title,
    urlKey: fm.urlKey,
    urlPath: data.fields.urlPath,
    type: fm.type,
    desc: fm.desc,
    youtube: fm.youtube,
    rutube: fm.rutube,
    vk: fm.vk,
    instagram: fm.instagram,
    timeOffset: fm.timeOffset,
    timeToRead: (data as PostDataFragmentFragment).timeToRead ? (data as PostDataFragmentFragment).timeToRead : undefined,
    date: fm.date,
    html: (data as PostDataFragmentFragment).html ? (data as PostDataFragmentFragment).html : undefined,
    body: (data as PostDataMdxFragmentFragment).body ? (data as PostDataMdxFragmentFragment).body : undefined,
    tags: fm.tags,
    dateValue: parseInt(data.fields.dateValue, 10),
    renderer: data.fields.renderer,
    imageOptions: fm.imageOptions,
    year: fm.year ? fm.year : undefined,
    bookInfo: fm.bookInfo ? fm.bookInfo : undefined,
    minImagesSize,
    cover,
    coverSize,
    wordCount,
    audioUrl,
    audio,
    audioList,
    images
  }
}